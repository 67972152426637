.AdditionalFaresFlightBox {
  &.AdditionalFaresFlightBoxSelected {
    .amenities {
      box-shadow: 8px 8px 10px -4px var(--secondary-color);
    }
    .price-wrapper {
      box-shadow: 8px 8px 10px -4px var(--secondary-color);
    }
  }

  display: flex;
  .price-header {
    border-radius: 40px 0 0 0;
    display: flex;
    background-color: var(--secondary-color);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    place-items: center;
    padding: 10px 60px;
    cursor: pointer;
  }

  .price-header-disabled {
    pointer-events: none;
    color: #ffffff4f;
  }
  .price-wrapper {
    border: 1px solid var(--secondary-color);
    border-radius: 40px;

    .total-price {
      color: var(--primary-color);
      font-size: 18px;
      font-weight: 600;
      padding: 20px 50px 20px 20px;
    }
    .price-diff {
      color: var(--primary-color);
      font-size: 18px;
      font-weight: 600;
      padding: 20px 50px 20px 20px;
    }

    .small-text {
      font-size: 12px;
    }
  }

  .amenities-wrapper {
    flex-grow: 1;
    transform: translateX(-30px);
    display: flex;
    flex-direction: column;
  }

  .amenities-wrapper:last-of-type {
    .amenities{
      border-bottom-right-radius: 40px !important;
    }
  }

  .amenity {
    min-width: 16%;
    padding: 10px 20px;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    border-left: 1px solid black;
    gap: 2px;
    text-align: center;
  }

  .check-amenities {
    text-decoration: underline;
    cursor: pointer;
  }

  .amenities {
    align-items: center;
    justify-content: end;
    display: flex;
    background: #fff;
    height: 100%;
    border-bottom: 1px solid var(--secondary-color);
    border-right: 1px solid var(--secondary-color);
    border-bottom-right-radius: 40px;
  }

  .different-fares {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;


    .amenities-wrapper .amenities{
      border-bottom-right-radius: 0;
    }

    .fare-name {
      border-radius: 0;
    }

    .amenities-wrapper:first-child {
      .amenities {
        border-radius: 0;
      }
      .fare-name {
        border-radius: 40px 40px 0 0;
      }
    }
  }

  .fare-name {
    background-color: var(--primary-color);
    border-radius: 40px 40px 0 0;
    text-decoration: none;
    width: 100%;
    padding: 10px 40px;

    margin-left: 40px;
    margin-right: unset;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 16px;

    span {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .info {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  @media screen and (max-width: 750px) {
    transform: translateX(20px);

    .fare-name {
      font-size: 12px;
      padding: 20px;

      .ant-btn {
        font-size: 12px !important;
        padding: 4px 8px;
      }
    }
    .price-header {
      font-size: 12px;
    }

    .price-header,
    .total-price,
    .price-diff {
      padding: 20px 45px 20px 15px !important;
      font-size: 12px !important;
    }

    .amenities {
      flex-direction: column;
    }
    .amenity {
      width: 100%;

      min-width: auto;
      font-size: 12px;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;

      svg {
        scale: 0.7;
      }
    }
  }

  &.additional-fares-content {
    width: 100% !important;
    transform: translateX(0);
  }
}


.direction-ltr, .additional-fares-modal-ltr{
  .AdditionalFaresFlightBox {

    .price-header{
      border-radius:0 40px 0 0
    }
    .fare-name{
      margin-left: 0;
    }

    .amenities-wrapper{
      transform: translateX(30px);
    }

    .amenity{
      border-left: none;
      border-right: 1px solid;
    }

    .amenities{
      border-left: 1px solid var(--secondary-color);
      border-right: none;
      border-bottom-right-radius:0
    }
  }

}
.non-chargeable-amenities {
  max-height: 350px;
  overflow: auto;
  .amenity-description {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px;
  }

  .amenity-type {
    color: greenyellow;
  }
  
}

.non-chargeable-amenities-tooltip {
  .ant-tooltip-content {
    width: max-content;
  }
}

.more-fares-button {
  margin: 0px auto;

  .ant-btn  {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    font-size: 24px;
    text-decoration: underline;
    border-radius: 10px;
  }
  svg {
    transform: rotate(180deg);
  }
  &.isMoreFaresVisible svg {
    transform: rotate(0deg);
  }
}

.additional-fares-modal {
  width: 80% !important;

  .ant-modal-body {
    padding: 40px;
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

  }

}

.additional-fares-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  h2 {
    font-weight: bold;
    margin: 40px;
  }
}

.direction-ltr .AdditionalFaresFlightBox {
  .fare-name {
    margin-left: unset;
    margin-right: 40px;
  }
}
