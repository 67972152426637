.agent-action-profit {
  display: flex;
  gap: 8px;
  align-items: center;
  > span {
    color: var(--primary-color);
    font-weight: 800;
  }

  .ant-input-group {
    display: flex;
    flex-direction: row-reverse;
    .ant-btn {
      border: none;
      padding: 4px 12px !important;
    }

    .btn-style-5 {
      .ant-btn-loading-icon {
        position: absolute;
        right: 0;
      }
    }

    input {
      border-radius: 8px;
      border-color: #000;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .ant-input-group-addon {
      &:first-child {
        border: none;
        padding: 0;
        background-color: transparent;
        padding-right: 5px;
      }
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 8px;
      border-color: #000;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .input-wrapper {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 16px;
  }

  .input-wrapper-disabled {
    opacity: 0.4;
  }

  .agent--action-input {
    border-radius: 8px;
    border-color: #000;

    &::placeholder {
      color: #000;
      font-weight: 800;
    }
  }
}

.direction-ltr {
  .agent-action-profit {
      .ant-input-group {
          direction: rtl;
      }
  }
}