.flight-outline-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    padding: 15px;
    gap: 15px;
    position: relative;
    .time {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 20px;
    }
    .hour {
      font-size: 28px;
      position: relative;
    }
    .days-difference {
      position: absolute;
      font-size: 12px;
      top: -5px;
      right: -5px;
    }

    .hour,
    .week-day {
      color: var(--tertiary-color);
    }
    .week-day {
        font-size: 17px;
    }

    .airline {
      position: relative;
      flex-grow: 1;
      max-width: 250px;
      margin: auto;
      overflow-x: hidden;

      .logos-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .logo.small {
          max-width: 35px;
          margin: unset;
        }
      }
    }

    .flight-fare{
        margin-bottom: 6px;
    }
    .flight-type, .flight-joint-name, .flight-fare{
      text-align: center;
    }
    .flight-stop {
      cursor: pointer;
      color: rgb(245, 128, 135);;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: baseline;
      gap: 10px;
    }


    .airline-line {
        position: relative;
    }
    .airline-horizontal-line {
      width: 100%;
      padding: 0 25px;
      font-size: 28px;
      border-bottom: 1px solid var(--primary-color);
      margin-bottom: 15px;
      padding-bottom: 5px;
      min-width: 150px;

      height: 20px;
    }
    .direction {
      position: absolute;
      top: 2px;
      right: 50%;
      transform: translateX(50%) rotate(180deg);
      color: var(--secondary-color);
    }

    .flight-baggage-col {
      padding-bottom: 10px;
    }
  }

  .open-fares-modal-button{
    transform: translate(-10px, 18px);
    @media screen and (max-width:750px) {
      margin-bottom: 50px;
    }
  }


  .hint-icon-wrapper {
    cursor: pointer;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
  
  // @media screen and(max-width:750px) {
  //   .flight-outline-section {
  //     flex-direction: column;
  //   }
  // }

  .direction-ltr {
    .flight-outline-section .direction {
      transform: translateX(50%) rotate(0deg);
    }

    .available-seats {
      right: 10px;
      left: auto;
      top: 5px;
    }
  }


// THESE STYLES ARE GLOBAL
.available-seats-9 {
    color: #c9c8c8;
}

.available-seats-8,
.available-seats-7,
.available-seats-6,
.available-seats-5 {
    color: #9d8d2f;
}
.available-seats-1,
.available-seats-2,
.available-seats-3,
.available-seats-4 {
    color: #f37777;
}
.available-seats {
    font-size: 13px;
    position: absolute;
    top: 5px;
    left: 5px;
}

#ShoppingCart {
    .flight-outline-section .week-day {
        font-size: 11px;
    }
    .available-seats {
        position: static;
        margin: auto;
    }
}

