.crud-message-box {
    padding: 80px 0px;
    text-align: center;
    border: 1px solid #dadcdb;
    border-radius: 12px;
    span {
        color: #8d918f;
    }
}
.empty-list {

}