.agent-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .input-wrapper {
    background: #dddddda1;
    border: 1px solid #bfbcbc;
    border-radius: 16px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    padding: 11px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .action-add-new-cart {
    border-radius: 32px !important;
    padding: 8px 15px !important;
    font-size: 10px;
    background-color: #000 !important;
    font-weight: 900;
  }

  .text {
    font-size: 18px;
    font-weight: 900;
  }
  .net-price-warning {
    font-size: 13px;
    margin-top: -11px;
    margin-right: 8px;
    svg {
      margin-bottom: -7px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }


  .action-disabled {
    opacity: 0.8;
  }
  .action {
    background: #eeedee;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 30%;
    align-items: center;
    cursor: pointer;
    gap: 16px;
    button {
      padding: 0 7px !important;
    font-size: 13px;
      border-radius: 7px !important;
    }

    img {
      max-width: 22px;
      max-height: 22px;
      margin-top: 10px;
    }
  }

  .action-selected {
    box-shadow: 0px 0px 0px 2px black;
  }
}

.cart-action-tooltip {
  width: 180px !important;
  .ant-tooltip-arrow-content {
    text-align: center !important;
  }
}