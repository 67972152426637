.shopping-cart-flights-package {
  .flights-package-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    > div {
      width: 100%;
    }

    svg {
      cursor: pointer;
    }
  }
  .flight-link {
    display: flex;
    margin-top: 5px;
    font-size: 16px;
  }
}
