.flight-card-2 {
  padding: 25px;
  border-radius: 16px;
  box-shadow: 0px 1px 2px 2px var(--primary-color);

  .dotted-border-top {
    border-top: 2px solid var(--tertiary-color);
  }
  .airplane {
    transform: rotate(45deg);
  }

  .baggage{
    place-content:center;
    margin-top: 20px;
  }

  .flights-wrapper {
    padding-right: 5px;
    border-right: 1px solid;
    border-left: unset;
    border-top: unset;
  }

  .logo {
    max-width: 70%;
    margin: 0 auto;
    display: block;
  }

  .airline-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  .airline-info {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;

    > span {
      color: var(--tertiary-color);
    }
  }

  &.flight-card-2-minimized {
    box-shadow: none;
    padding: 15px 0px !important;
  
    .flight-outline-section {
      gap: 10px;
      padding: 0px 5px;
      .airline{
        max-width: 200px;
        .airline-horizontal-line{
          display: none;
        }

        .direction{
          display: none;
        }
      }
    }
  
    .logo {
      max-width: 40px;
      max-height: 40px;
      margin: 0;
    }
  
    .time {
      font-size: 14px;
    }
  
    .hour {
      font-size: 16px;
    }
  
    .baggage {
      margin: 0;
      .ant-badge-dot{
        display: none;
      }
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;

      svg{
      }

      span{
        text-align: center;
        width: fit-content;
        flex-direction: row;
        display: flex;
        gap: 10px;
      }
    }
  
    .flights-wrapper {
      border-right: unset;
      border-left: unset;
    }

    .amenities-tooltip, .airline-logo-with-tooltip{
      display: flex;
      gap: 10px;
      padding-top: 10px;
      justify-content: center;
    }

    .amenities-tooltip{
      span{
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }

    .airline-logo-with-tooltip-wrapper{
      display:flex; 
      gap:10px; 
      align-items:center;
    }

  
    .flight-stop-details-wrapper .flight-timeline-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  
    .flight-stop-details-wrapper {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0;
  
      .flight-timeline-wrapper {
        gap: 5px;
  
        > div {
          margin: 0 !important;
        }
      }
    }
  
    .flight-stop-details-wrapper .flight-timeline-wrapper {
      .ant-timeline {
        margin: 5px 0;
      }

      .timeline-item-row {
        font-size: 12px;
    
        .hour {
          font-size: 12px;
        }
      }

      .flight-duration {
        font-size: 12px;
      }
      .airport-wait-duration {
        min-width: auto;
        gap: 10px;
        font-size: 12px;
    
        .type,
        .wait-type {
          font-size: 12px;
        }
      }

    } 
  
    .ant-timeline-item {
      padding-bottom: 10px;
    }
  
    .flight-stop-details-wrapper .flight-timeline-wrapper .airport-wait-duration {
      min-width: auto;
      gap: 10px;
      font-size: 12px;
  
      .type,
      .wait-type {
        font-size: 12px;
      }
    }
  }
}

  @media screen and(max-width:750px) {
    .flight-card-2 {
      padding: 15px 0px;
      .flights-wrapper {
        border-top: 1px solid;
        border-right: unset;
        border-left: unset;
      }

      .flight-outline-section{
        display: flex;
        flex-wrap: wrap;
      }

      .flight-button-2{
        padding: 10px;
        place-content: center;
      }

    }
  }

.direction-ltr {
  .flight-card-2 {
    .flights-wrapper {
      padding-left: 5px;
      border-left: 1px solid;
      border-right: unset;
      border-top: unset;
    }
  }

  @media screen and(max-width:750px) {
    .flight-card-2 {
      padding: 15px 0px;
      .flights-wrapper {
        border-top: 1px solid;
        border-right: unset;
        border-left: unset;
      }
    }
  }
}
