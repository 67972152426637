.cookie-message-container {
  direction: rtl;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  border: 1px solid var(--primary-color);
}

.cookie-message-text {
  margin: 0;
  padding-right: 10px;
}

.cookie-message-accept-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
