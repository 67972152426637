.agent-action-share {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #eeeeee;
  border-radius: 8px;
  padding-bottom: 15px;
  .option {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    padding: 20px;
    display: flex;
    align-content: center;
    gap: 20px;
    background-color: #d8d8d8;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  .share-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    > span {
      display: flex;
      gap: 20px;
      align-items: center;
    }

    svg {
      cursor: pointer;
    }

    img {
      max-width: 25px;
      max-height: 25px;
    }

    .text {
      font-size: 12px;
      font-weight: bold;
      display: flex;
      flex-direction: column;

      span:first-child {
        font-size: 18px;
      }
    }
  }
}
