@font-face {
    font-family: 'Heebo';
    src: url('static/Heebo-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Heebo';
    src: url('static/Heebo-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Heebo';
    src: url('static/Heebo-ExtraLight.ttf') format('truetype');
    font-weight: 100;
}


body, #App [class*=" ant-col"] {
    font-family: 'Heebo', sans-serif !important;
}