.input-with-moving-placeholder {
  position: relative;

  .ant-input, .ant-select-selector {
    border-radius: 16px !important;
  }

  /* Hide arrows for Chrome, Safari, Edge, and Opera */
  .ant-input[type="number"]::-webkit-outer-spin-button,
  .ant-input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  /* Hide arrows for Firefox */
  .ant-input[type="number"] {
      -moz-appearance: textfield;
  }

  .placeholder {
    // width: 100%;
    overflow-x: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;

    position: absolute;
    z-index: 1;
    bottom: 0;
    font-size: 12px;
    padding: 6px 11px;
    color: #ccc;
    transition: all 100ms ease-in;
    text-wrap: nowrap;
  }

  &.ant-input-focused {
    border: none !important;
    box-shadow: none !important;
    .placeholder {
      overflow-x: visible;
    }
  }

  &.ant-input-focused .placeholder {
    transform: translateY(-25px);
    scale: 0.8;
    padding: 2px 5px;
    background-color: #fff;
    color: var(--primary-color);
    line-height: 2px;
    bottom: 8px;
  }
}
