.shopping_cart {
  transition: all 0.5s ease-in-out;
  z-index: 100;
  position: fixed;
  left: 5px;
  top: 17vh;
  background-color: #fff;
  border: 1px solid var(--primary-color);
  border-radius: 12px;
  width: 120px;
  &.cart-open.shopping_cart-agent {
    width: 384px;
  }
  &.cart-open {
    width: 282px;
  }
  > .header {
    transition: all 0.5s ease-in-out;

    box-shadow: 0px 0px 20px #fff;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    color: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    gap: 10px;
    font-weight: bold;


    .shopping-cart-svg{
      max-width: 55px;
      max-height: 55px;
      top: -29px;
      left: 2px;
      fill: #fff;
      rotate: 14deg;
      scale: 1.4;
    }

    .cart-title{
      display:flex; 
      flex-direction:column; 
      gap:10px; 
      align-items:center;
    }
    .logo {
      max-width: 55px;
      max-height: 55px;
      top: -29px;
      left: 2px;
      background-color: transparent;
      g:nth-child(3) {
        fill: var(--primary-color);
      }
    }


    .clear-cart{
      margin-right: auto;
      margin-left: 10px;
    }
    .assign-customer-button{
      margin-right: auto;
    }

    .create-order-button{
      width: max-content;
    }
    .assign-customer-button, .create-order-button{
      &:hover {
        box-shadow: 0px 0px 10px #ffffff
      }

      background-color: #fff !important;
      color: #000 !important;
      padding: 2px 10px !important;
    }
    .customer-name{
      padding: 5px;
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .customer-name:hover{
      position: relative;

      .edit-user{
        cursor: pointer;
      }
    }
  }
  .close {
    position: absolute;
    top: -10px;
    right: -13px;
    cursor: pointer;
    height: 25px;

    width: 25px;
    border-radius: 50%;
    stroke: #fff;
    fill: #fff;
    background: #000;
  }

  .header-closed {
    padding: 15px;
    padding-top: 25px;
    padding-bottom: 5px;
    margin: 0;
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;

    .shopping-cart-svg{
      rotate: none;
      scale: 1;
      transform: translate(0px, -10px);
    }

    .logo {
      max-width: 75px;
      max-height: 75px;
      margin-top: -40px;
    }
  }

  .content {
    width: 280px;
    height: 60vh;
    min-height: 350px;
    max-height: 440px;
    padding: 7px;
    font-size: 12px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 50px;

    > div:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    > div {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1.2px solid #2e2e2e8c;

      > b {
        margin-bottom: 10px;
        display: block;
      }
    }
  }

  @media screen and(max-width: 750px) {
    .content {
      max-height: unset;
      padding: 14px;
    }
  }

  .footer:has(.free-package) {
    bottom: -20px;
  }
  .free-package {
    margin: auto;
  }

  .cart-empty {
    text-align-last: center;
    max-height: 250px;

    & + .footer {
      .currency-sign {
        display: none;
      }
      bottom: -22px;
    }
    > div {
      border: none;
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      color: var(--primary-color);
    }
    .empty-text {
      color: inherit;
    }
    > svg {
      margin-top: 40%;
      color: var(--primary-color);
    }
  }

  .shopping-cart-flights {
    .product-title {
      margin-bottom: 32px;
    }
  }
  .product-title {
    display: flex !important; 
    gap: 8px; 
    align-items: center;
    justify-content:space-between;
    font-size: 20px;
    margin-bottom: 5px;

    .btn-style-4{
      text-decoration: underline;
    }
  }
  .title {
    color: var(--primary-color);
    margin-bottom: 5px;
  }

  .price-delete {
    display: flex;
    align-items: center;
    gap: 5px;

    b{
      white-space: nowrap;
    }
  }

  .price-delete-flight{
    flex-direction: column;
    align-items: end;
  }

  .applied-subsidize {
    color: var(--primary-color);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 15px;

    .price {
      font-size: 13px;
      white-space: nowrap;
      color: initial;
    }
  }

  .rooms-activities {

    .small-info-group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 10px;

      .hint {
        cursor: pointer;
        min-width: 18px;
        &:hover {
          background-color: var(--primary-color);
          border-radius: 50%;
          fill: var(--background-color);
        }
      }
    }
    .occupiers{
      display: flex;
      gap: 4px;
      align-items: baseline;
    }

    > div {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 20px;
      font-size: 13px;
      justify-content: space-between;
    }

    .product-line-passenger{
      align-items: start;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .product-line {
      &:hover .delete-product {
        display: inherit;
        cursor: pointer;
      }
      .activity {
        gap: 10px;
        display: flex;
        align-items: center;
        font-size: 12px;
      }

      .index {
        background-color: var(--primary-color);
        min-height: 30px;
        min-width: 30px;
        border-radius: 50%;
        color: #fff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .board{
        font-size: 11px;
      }

      .name {
        color: var(--primary-color);
      }


      .adult{
        position: relative;

        span{
          color: var(--primary-color);
          position: absolute;
          font-size: 10px;
          left: 3px;
          bottom: -4px;
        }
      }

      .child{
        position: relative;

        svg{
          width: 13px;
        }
        span{
          color: var(--primary-color);
          position: absolute;
          font-size: 7px;
          left: 3px;
          bottom: -2px;
        }
      }
    }
  }

  .footer {
    &:hover .move-to-checkout {
      text-decoration: underline;
    }
    position: absolute;
    bottom: -43px;
    left: 2.5%;
    width: 92%;
    margin-left: 2%;
    cursor: pointer;

    .move-to-checkout {
      margin: auto;
      background-color: #000;
      color: #fff;
      text-align: center;
      display: block;
      border-radius: 0 0 11px 11px;
      line-height: 27px;
      font-weight: bold;
      margin-top: -12px;
      padding-top: 18px;
      padding-bottom: 5px;
      z-index: 1;
      position: relative;
    }

    > button,
    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      z-index: 2;
      position: relative;
      .currency-sign {
        background-color: #fff;
        border-radius: 50%;
        color: var(--primary-color);
        width: 20px;
        height: 20px;
        padding: -3px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 10px;
  }

  // .delete-product {
  //   cursor: pointer;
  //   display: none;
  // }

  .discount-code {
    display: flex;
    gap: 5px;
    justify-content: center;
    margin-right: -16px;
    margin-bottom: 30px;

    input {
      width: 100%;
      font-size: 14px;
    }
    button {
      font-size: 15px;
      padding: 5px 12px !important;
      margin-top: 2px;
    }
  }
  .input {
    border-radius: 32px;
    padding: 6px;
    font-size: 10px;
    width: 100px;
  }
  .product-activity {
    display: flex;
    column-gap: 6px;
    .amount-x {
      font-size: 13px;
      font-weight: bold;
    }
  }

  .attraction-product {
    .last-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    
      .product-guests {
        margin: 5px 0px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        flex-flow: row wrap;

        .icon-box {
          display: flex;
          gap: 5px;
          align-items: center;
        }

        .ico-int {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;

          svg {
            width: 18px !important;
            height: 18px !important;
          }

          &.ico-Child svg {
            width: 10px !important;
            height: 10px !important;
          }
          &.ico-Infant svg {
            width: 12px !important;
            height: 12px !important;
          }
        }
      }
      .price-box {
        font-size: 13px;
        font-weight: bolder;
        display: flex;
        align-items: center;
        gap: 5px;
        span {
          word-break: keep-all;
          text-wrap: nowrap;
        }
        svg {
          cursor: pointer;
        }
      }
    }
  }

  #code-info {
    background: var(--primary-color);
    padding: 11px;
    border-radius: 10px;
    width: 80%;
    margin-right: 5%;
    margin-bottom: 11px;
    text-align: center;
  }

  .package-general-info{
    border-bottom: 1px solid var(--primary-color) !important;
    padding-bottom: 5px;
    margin-bottom: 5px;
    
  }
  .total-cart-pricing {
    list-style: none;
    padding: 0;
    width: 100%;
    margin: 0;
    .total-agent-comm {
      border-bottom: 1px dotted;
      margin-bottom: 3px;
    }
    li {
      padding: 2px 10px;
      display: flex;
      justify-content: space-between;
    }
  }

  .cart-not-valid{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .product-top {
      > .product-title {
        border-bottom: 1px solid #ccc;
        border-top: 1px solid #ccc;

        // &:hover {
        //   text-decoration: underline;
        //   cursor: pointer;
        // }
        a {
          color: #000;
          font-size: 15px;
        }
    }

    .sub-products-title {
      font-size: 14px;
    }
  }

  .update-commission{
    .button-with-arrow.ant-btn {
      padding: 4px 8px !important;
      font-size: 12px;
      background: #ffec36;
      svg {
        font-size: 20px;
      }
    }
  }
}

.add-room-to-cart-warning, .max-seat-in-flight-warning{
  padding: 0 !important;
  .ant-notification-notice-message{
    padding: 0 !important;
    margin: 0 !important;

  }
}

.max-seat-in-flight-alert{
  display: flex;
  gap:10px
}

.shopping_cart-agent{
  .content{
    width: 380px;
    padding-bottom: 50px;
  }

  .footer{
    bottom: -70px;
  }
}


.shopping_cart.flight-only {
  .rooms-activities>b, .package-info , .board  {
    display: none;
  }

}