.taxes-tooltip {
    padding: 0px 15px;
    h2 {
        margin: 10px 10px 10px 0px;
        color: var(--background-color);
    }
    .bold {
        font-weight: 700;
        color: #fff;
    }
}