.gradient_button {
    background: linear-gradient(var(--primary-color), var(--secondary-color)) !important;
    color: #fff !important;
    font-weight: bold !important;
}

.App {
    background-color: var(--background-color);
    font-family: var(--font-family);

    @media screen and (max-width: 750px) {
         > .shopping_cart{
            display: none;
        }
    }
    .ant-form-item-label {
        white-space: normal;
        &.ant-col-rtl {
            text-align: right;
        }
        > label {
            min-height: 32px;
            height: auto;
        }
    }

    .back-to-top {
        position: fixed;
        bottom: 20px;
        right: 20px;
        border: 2px solid;
        border-radius: 50%;
        color: var(--primary-color);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 50;
      }
      
      .back-to-top:hover {
        color: var(--secondary-color);
      }
}

.ant-tooltip-content {
    .ant-tooltip-inner {
        border-radius: 10px ;
    }
}

.bold {
    font-weight: bold;
}

.border {
    border: 2px solid var(--primary-color);
    border-radius: 10px;
}

.center {
    position: absolute !important;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

.price_box {
    color: #fff;
    background: linear-gradient(var(--primary-color), var(--secondary-color));
    padding: 5px;
    height: fit-content;

    >div:nth-child(2) {
        font-size: 14px;
    }

    .price {
        font-size: 20px;
        font-weight: bold;
    }

    .link {
        text-decoration: underline;
        cursor: pointer;
    }
}

.center-content {
    place-content: center !important;
  }
.packageBox2 {
    .price-box-2 {
        margin-top: 10px;
        >span {
            position: relative;
            top: -11px;
        }
        >p {
            margin-bottom: -10px;
        }

        >div {
            font-size: 30px;
            font-weight: bold;
            position: relative;

            &::after {
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-right: 15px solid #fbd62d;
                margin: 0;
                display: inline-block;
                vertical-align: middle;
                width: 0;
                height: 0;
                content: " ";
                position: absolute;
                left: -18px;
                top: 10px;
            }
        }
    }
}

.button-with-arrow.ant-btn {
    border: none;
    box-shadow: none;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    font-size: 18px;
}
.button-with-arrow-disabled{
    opacity: 0.5;
}


.content-wrapper{
    max-width: 1100px;
    margin: auto;
    width: 90vw;
}


// #############BUTTONS
.ant-btn[disabled]{
    opacity: 0.7;
}
.search_button {
    background-color: var(--secondary-color) !important;
    color: #000 !important;
    font-size: 30px !important;
    border-radius: 8px !important;
    height: 60px !important;
}

.search_button-disabled{
    opacity: 0.7;
}

.btn-style-1 {
    border-radius: 10px !important;
}

.btn-style-2 {
    border-radius: 10px !important;
    background: transparent !important;
    border: 2px solid var(--primary-color) !important;
    color: black !important;
}

.btn-style-3 {
    background: transparent !important;
    border: 2px solid var(--primary-color) !important;
    color: black !important;
}

.btn-style-4 {
    background: none !important;
    border: none !important;
    color: var(--primary-color) !important;
    box-shadow: none !important;
}

.btn-style-5 {
    background: var(--primary-color) !important;
    border: none !important;
    color: #fff !important;
    border-radius: 16px !important;
}

.btn-style-6{
    background: var(--primary-color) !important;
    border: none !important;
    color: #00b4f5 !important;
    border-radius: 16px !important;
    font-size: 18px;
    margin-right: 10px;
}

.btn-style-7 {
    border-radius: 16px !important;
    background: transparent !important;
    border: 2px solid var(--primary-color) !important;
    color: var(--secondary-color) !important;
    font-weight: 900 !important;
  }

.btn-style-1,
.btn-style-2,
.btn-style-3,
.btn-style-4,
.btn-style-5,
.btn-style-6,
.btn-style-7 {
    padding: 10px 20px !important;
    height: auto !important;
    display: flex !important;
    align-items: center !important;
    gap: 15px !important;
}

.pointerEventsNone {
    pointer-events: none;
}





// #############TITLES

.title-style {
    span:nth-child(2) {
        color: var(--secondary-color);
        font-weight: 300;
    }

    span:nth-child(1) {
        font-weight: 400;
        color: var(--primary-color);
    }
}

.title-style-1 {
    font-size: 40px;

    span:nth-child(1) {
        margin: 0 10px;
    }
}


h2.title-style-2 {
    font-size: initial;
}
.title-style-2 {
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 40px;
}

.title-style-3 {
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 40px;

    span:nth-child(2) {
        color: var(--primary-color);
    }
}


.title-style-4 {
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 40px;
}

.title-style-5{
    margin-bottom: 25px;
    
    span:nth-child(1) {
        color: var(--tertiary-color) ;
        font-size: 32px;
        display: block;
    }
    span:nth-child(2) {
        color: var(--tertiary-color) ;
    }
}


// #RESPONSIVE DATEPICKER 

@media screen and (max-width: 550px) {
    // .ant-picker-panels { 
    //     flex-direction: column !important;
    //   } 
    .ant-picker-dropdown-range:not(.ant-picker-dropdown-rtl) .ant-picker-panel {
        position: relative;
        &:first-child {
            .ant-picker-header {
                .ant-picker-header-super-next-btn, .ant-picker-header-next-btn, .ant-picker-header-view {
                  visibility: visible !important;
                }
            }
        }
        &:last-child {
            display: none;
            width: 0;
            // .ant-picker-header {
            //     position: absolute;
            //     // right: calc(50% + 30px);
            //     // transform: translateX(50%);
            //     height: 41px;
            //     right: 0;
            //     .ant-picker-header-super-prev-btn, .ant-picker-header-prev-btn, .ant-picker-header-view {
            //       visibility: hidden;
            //     }
            //   }
          
            //   .ant-picker-body {
            //     display: none;
            //   }
        }
    }
    .ant-picker-dropdown-range.ant-picker-dropdown-rtl .ant-picker-panel {
        position: relative;
        &:first-child {
            display: none;
            width: 0;
            // .ant-picker-header {
            //     position: absolute;
            //     height: 41px;
            //     left: 0;

            //     .ant-picker-header-super-prev-btn, .ant-picker-header-prev-btn, .ant-picker-header-view {
            //       visibility: hidden;
            //     }
            //   }
            //   .ant-picker-body {
            //     display: none;
            //   }
        }
        &:last-child {
            .ant-picker-header {
                .ant-picker-header-super-next-btn, .ant-picker-header-next-btn, .ant-picker-header-view {
                  visibility: visible !important;
                }
            }
        }
    }
}

.universal {
    .PackageDetails,.PackageRoomPricing, .activities_set1 h4,#page-content-set-1 .airplane {
        display: none;
    }
    #footer {
      border-top: 3px solid var(--primary-color);;
    }
}
.issta {
    .room-pricing-wrapper .price-comment {
        background-color: #faffda;
        line-height: 26px;
        margin-bottom: -6px;
    }
    .g-passport {
        flex-basis: 100%;
        max-width: 100%;
        .ant-form-item-control-input-content {
            max-width: 249px;
            &::before {
                content: "מס׳ דרכון";
                display: inline-block;
                padding-left: 15px;
                }

            input {
                width: 150px;
                &::-webkit-input-placeholder { /* WebKit browsers */
                    color:    #fff;
                }
                &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                    color:    #fff;
                    opacity:  1;
                }
                &::-moz-placeholder { /* Mozilla Firefox 19+ */
                    color:    #fff;
                    opacity:  1;
                }
            }
        }
    }
    .flight-box .additional-info {
        display: none;
    }
    .order-info-form .room-guests .title>p {
        font-weight: bold;
    }
    .hotel-info-wrapper .affiliate-info {
        top: 50px;

        img {
            max-height: 32px;
        }
    }
    .personal_info {
        border: 1px solid #a17e04;
        margin-bottom: 20px;
        border-radius: 23px;
        padding: 19px;
        background-color: #f6f5f1;
        .g-first-name, .g-last-name, .g-birthdate, .g-passport {
            min-width: 190px;
        }

        .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked{
            background-color: var(--primary-color);
        }
    }
    .hotel-name {
        text-align: left;
    }
    .hotel-stars {
        svg {
            color:rgb(117 99 26) !important;
        }
    }
    .hotel-info-wrapper {
        //background-position: 0px -380px;
    }
    .package-info-modal .modal-title {
        color: #000;
    }
    .package-info-modal .ant-modal-body {
        background-color: #ecd385 !important;
        color: #000 !important;
    }
    .header-nav .header-links a img {
        max-height: 130px;
    }
    .header-nav .header-wrapper {
        padding: 0;
    }
    .order-info-form .guests-info-rooms .title>span:last-child {
        font-size: 20px;
        font-weight: bold;
    }
    .checkout-wrapper .background {
        height: 80vh;
        object-position: 0 -290px;
        width: 100vw;
    }
    .shopping_cart .header-closed.header .logo {
        position: absolute;
        width: 106px;
        top: -52px;
        left: 17px;
    }
    .shopping_cart > .header {
        color: var(--primary-color);
    }
    .shopping_cart > .header .logo {
        position: absolute;
        width: 129px;
        top: -57px;
        left: 62px;
        background-color: transparent;
    }
    .shopping_cart > .header .umbrella {
        display: none;
    }

    @media screen and (max-width: 750px) {
        .shopping_cart > .header .logo {
            left: 111px;
        }
        .header-nav .shopping_cart {
            top: 127px;
        }
         .checkout-wrapper .background {
             object-position: -371px -20px;
         }
    }
}
.ipc {
    .shopping_cart .header-closed .logo {
        position: absolute;
        width: 75px;
        top: -7px;
        left: 37px;
    }
}
.skystudents {
    @media screen and (max-width: 550px) {
        .header-nav .burger-menu a > img {
            height: 46px;
        }

        .header-nav .burger-menu {
            background: #10b6f1;
        }


        .footer .right-image {
            bottom: 250px;
            max-width: 50px;
        }

        .header-nav{

            .right-image,
            .left-image{
                max-width: 50px;
                bottom: 40px;
            }
        }
    }
    .footer .right-image {
        right: 0px;
    }

    .header-nav {
        .right-image{
            display: block;
            position: absolute;
        }
        .left-image{
            left: 0;
            position: absolute;
        }
    }
}
.soho {
    .shopping_cart .header-closed .logo {
        top: -44px;
        left: 29px;
    }
    .header-nav .header-links a img {
        max-height: 90px;
    }
    .header-nav .header-wrapper {
        padding: 10px 35px;
    }

    .hotel-info > h4, .hotel-info > div {
        color: #fff;
    }
    &.package-info-modal .hotel-additional-includes,
    #package-details .hotel-additional-includes,
    .shopping_cart .content>div:last-child {
        display: none;
    }
    @media screen and (max-width:1600px) {
        .hotel-info-wrapper {
            background-position-y: 0px;
        }
    }

    .personal_info {
        border: 1px solid #a6ce3ac2;
        margin-bottom: 20px;
        border-radius: 23px;
        padding: 19px;
        background-color: #b8d86f1c;
        .g-first-name, .g-last-name, .g-birthdate, .g-passport {
            min-width: 190px;
        }

        .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked{
            background-color: var(--primary-color);
        }
    }
    #promo-page {
           #search-date-picker {
               display: none;
           }
         .header {
             border-bottom: 3px solid #24bdad;
             padding: 21px;
             border-top: 3px solid #a6ce39;
         }
        .package_box-header::after {
            border-top: 25px solid #f9b716;
        }
        .package_box2 {
            .price, .gift{
                display: none;
            }
            .dates {
                font-size: 20px;
                text-align: center;
                border-top: 1px solid #3bbdad;
                padding-top: 10px;
            }
        }
         .logo {
             max-height: 100px;
         }
    }
    .room-pricing-wrapper .price-comment {
        background: #f9fbf2;
        bottom: 7px;
    }
    .option-includes > div, .subsidized-form-wrapper .label {
        display: none;
    }
    .subsidized-form-wrapper {
            .input-wrapper {
                padding-top: 24px;
            }
         .subsidized-form {
             margin-top: -25px;
         }
    }
}

.EDIT {
  position: relative;

  .EDIT-BUTTON {
    height: 100%;
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-direction: column;


    >div{
        padding: 20px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        gap: 20px;
        /* justify-content: center; */
        background: white;
        margin-right: auto;
        border-radius: 10px;
    }



    .add-btn-top{
    background: #6f56f9;
    border-radius: 16px;
    place-content: center;
    position: absolute;
    top: 0;
    width: min-content;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    }

    .add-btn-bottom{
        background: #6f56f9;
        border-radius: 16px;
        place-content: center;
        position: absolute;
        bottom: 0;
        width: min-content;
        position: absolute;
        right: 50%;
        transform: translateX(50%);
    }


    // .delete{
    //     background-color: rgb(253, 49, 49);
    // }

    // .edit{
    //     background-color: rgb(41, 255, 44);
    // }

    position: absolute;
    display: none;
    button {
    //   z-index: 100;
    //   display: flex;
    //   align-items: center;
    //   gap: 20px;
    //   border: none;
    }
  }

  &:hover {
    .EDIT-BUTTON {
      background-color: #6e56f91f;
      display: flex;
      gap: 20px;
    }
  }
}



.header-section {
    font-size: 32px;
    font-weight: 1000;
    margin-bottom: 15px;
}


.shopping-cart-notification {
  display: none;
}
@media screen and(max-width: 750px) {
    .shopping-cart-notification {
      display: block;
    }
  }
  

@media screen and (max-width: 800px) {
    .header-section {
        font-size: 25px;
        text-align: center;
        border: 1px solid var(--primary-color);
        border-radius: 15px;
        padding: 10px;
        margin-bottom: 20px;
    }
}
.b2c-sky360 {
    .room-pricing-filters .filter-text {
        color: #fff;
    }
}

.direction-rtl{
    .EDIT .EDIT-BUTTON > div{
        margin-right: 0;
        margin-left: auto;
    }
}



