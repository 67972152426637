.cancellation-tooltip {
    padding: 0px 15px;
    h2 {
        margin: 10px 10px 10px 0px;
        color: var(--background-color);
    }
    .policy-items-wrapper {
        .policy-item {
            margin-left: 8px;
            position: relative;

            .bold {
                font-weight: 700;
                color: #fff;
                margin: 0px 5px;
            }
    
            &::after {
                position: absolute;
                content: '';
                left: -16px;
                top: 0;
                height: 40px;
                width: 8px;
                border-radius: 4px;
            }

            &.free::after{
                background-color: greenyellow;
            }
            &.half::after{
                background-color: orange;
            }
            &.full::after{
                background-color: red;
            }
        }
    }

}

.ant-tooltip-rtl .cancellation-tooltip {
//.direction-rtl .cancellation-tooltip {
    .policy-items-wrapper {
        margin-right: 10px;
        .policy-item::after {
            right: -16px;
            left: 0;
        }
    }
}