.agent-commission-search {
  display: flex;
  justify-content: center;
  gap: 20px;
  background-color: var(--primary-color);
  color: #fff;

  &.default-view {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: clamp(250px, 30%, 280px);

    .commission {
      position: relative;
      span {
        font-size: 20px;
        overflow-wrap: anywhere;
      }
    }
  
    .supplier {
      position: relative;
  
      &::after {
        content: ' ';
        position: absolute;
        height: 80%;
        width: 1px;
        background-color: #fff;
        left: -6px;
        right: unset;
        top: 10%;
      }
      span {
        font-weight: lighter;
        overflow-wrap: anywhere;
      }
    }
    .commission,
    .supplier {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
  }


  &.internal-package-box-view {
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
    column-gap: 15px;
    padding: 5px 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    // color: var(--primary-color);

    .commission, .supplier {
      margin-right: 10px;
      margin-left: unset;
      position: relative;

      &::after {
        width: 5px;
        height: 5px;
        background-color: var(--primary-color);
        position: absolute;
        content: '';
        border-radius: 50%;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &.centered {
    margin-right: auto;
    margin-left: auto;
  }
  &.isMoreDealsVisible {
    position: absolute;
    top: 0px;
    right: 20px;
    width: 180px;
    gap: 20px;
    .supplier span {
      font-size: 14px;
    }
  }
}

.direction-ltr {
  .agent-commission-search.default-view .supplier::after{
    right: -6px;
    left: unset;
  }
  
}