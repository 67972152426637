.ant-input-container {
  position: relative;

  .placeholder {
    position: absolute;
    bottom: 0;
    z-index: 1;
    font-size: 12px;
    padding: 6px 11px;
    color: #ccc;
    transition: all 100ms ease-in;
  }

  .ant-input-focused .placeholder {
    transform: translateY(-25px);
    scale: 0.8;
    padding: 2px 5px;
    background-color: #fff;
    color: var(--primary-color);
  }
}
