.flight-stop-details-wrapper {
    position: relative;
    display: flex;
    flex-direction: row-reverse;

    // background-color: #fafafa;
    padding: 20px 50px;
    margin-bottom: 10px;
    border-top: 2px dotted var(--tertiary-color);

    .flight-timeline-wrapper {
        width: 82%;
        margin: 0px 0px;

        .timeline-title {
            display: flex;
            align-items: center;
            gap: 18px;
            img{
                margin: 0;
            }
            .names-wrapper {
                display: flex;
                flex-direction: column;
                .airlineName {
                    color: var(--tertiary-color);
                    font-size: 16px;
                }
            }
        }


        .ant-timeline {
            margin: 10px 0px;
        }
        .ant-timeline-item-content {
            top: -5px;
        }
        .ant-timeline-item-last>.ant-timeline-item-content {
            min-height: unset;
        }

        .ant-timeline-item-last {
            padding-bottom: unset;
        }
        .timeline-item-row {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            font-size: 16px;
            gap: 10px;

            .hour {
                position: relative;
                .days-difference {
                    position: absolute;
                    font-size: 12px;
                    top: -8px;
                    left: -8px;
                    right: unset;
                  }
            }
        }
        .flight-duration {
            display: flex;
            align-items: baseline;
            gap: 10px;
            font-size: 16px;
            color: #7d7f8b;
        }
        .airport-wait-duration {
            padding: 10px 20px;
            margin: 10px 0px;
            background-color: #fafafa;
            // background-color: var(--primary-color);;
            max-width: max-content;
            min-width: 280px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            font-size: 16px;

            .time {
                font-weight: 700;
            }

            .wait-type {
                font-weight: 700;
                font-size: 18px;
            }
        }
        .long-wait {
            color: rgb(245, 128, 135);;
        }

    }
    .flight-general-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }
    
}

@media screen and(max-width:750px) {
    .flight-stop-details-wrapper{
      flex-direction: column;
    }
    .flight-timeline-wrapper {
        width: 100%;
    }
}

.direction-ltr {
    .timeline-item-row .hour .days-difference {
        right: -8px;
        left: unset;
    }
}