.EDIT {
  // cursor: grab;
  .arrows-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // position: absolute;
    // right: 100px;

    .disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }
  .button {
    cursor: pointer;

    &:hover {
      color: var(--ant-primary-color);
    }
  }

  &.ONLY-EDIT {
    cursor: default;
  }
}

.EDIT-MODE-ON {
  .EDIT-BUTTON {
    box-shadow: inset 0px 0px 0px 3px #6f56f9;
    display: flex !important;
    background-color: transparent;
    border-radius: 10px;
  }
}

.IS_CLICKING_DISABLED{
  *{
    pointer-events: none !important;
  }

}

.EDIT-LAYOUT {
  .EDIT-BUTTON {
    box-shadow: inset 0px 0px 0px 3px #f9aa56;
  }

  &:hover {
    .EDIT-BUTTON {
      background-color: #f9b2561f !important;
      display: flex;
      gap: 20px;
    }
  }

  .add-btn-bottom,
  .add-btn-top {
    background: #f9aa56 !important;
  }
}
