.spin-loader {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  //   border-radius: 4px;
}
.spin-loader-on-top {
  position: relative;
  padding: 0;
  .spin-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: rgb(255 255 255 / 70%);

    > span {
      position: relative;
      top: 41%;
    }
  }
}
