.ant-modal.upgrade-plan-template-modal {
    padding-bottom: 0px;
    border-radius: 20px !important;
    max-width: 370px;

        .ant-alert {
        margin-top: 20px;
    }
    .ant-modal-content {
        border-radius: 20px !important;
        padding: 30px 20px 20px 20px;
        .ant-modal-body {
            padding: 0px;
        }
    }
}

.upgrade-plan-template-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-width: 290px;
    margin: 0px auto;

    .logo-wrapper {
        width: 100%;
        // background:  var(--primary-color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        svg {
            height: 50px;
        }
    }
    .banner-image {
        width: 100%;
        height: 100px;
        background: #43DCFF;
        border-radius: 50px;
        padding: 10px 0px;
    }

    .template-title {
        color: var(--primary-color, var(--ant-primary-color));
        font-weight: bolder;
        font-size: 21px;
        line-height: 24px;
    }
    .inclusions {
        .benefits-title-text {
            font-size: 13px;
            color: var(--tertiary-color, var(--secondary-color, #9B9B9B));
            margin-bottom: 20px;
        }
        svg {
            // margin-top: 5px;
            width: 20px;
            height: 20px;
        }
        p {
            margin-bottom: 4px;
            display: flex;
            // justify-content: center;
            align-items: center;
            gap: 10px;
            margin: 0px 10px;
            .line {
                width: 100%;
                font-size: 12px;
                font-weight: bolder;
                // line-height: 1;
            }
        }
        .horizontal-line {
            border-bottom: 2px solid var(--tertiary-color, var(--secondary-color, #9B9B9B));
            margin: 15px 0px;
        }
    }

    .upgrade-btn {
        margin-top: 30px;
        font-size: 14px;
        text-decoration: underline;
    }
}