.flight-button-2 {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 35px;
  justify-content: end;
  border-top: 2px dotted var(--tertiary-color);
  padding-top: 12px;
  margin-top: 12px;

  > div {
    display: flex;
    align-items: center;
    gap: 35px;
  }

  .no-availability-text {
    font-size: 20px;
    font-weight: bold;
    width: min-content;
    min-width: 300px;
  }

  .info {
    display: flex;
    flex: 1;
    gap: 10px;
    font-size: 14px;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;

    span {
      max-width: 130px;
    }

    svg {
      border-radius: 50%;
      background-color: #d2d2d2;
      padding: 5px;
    }
  }

  .number-of-passengers {
    font-size: 20px;
    filter: brightness(0.5);
    color: var(--tertiary-color);
    font-weight: bold;
  }

  .single-price {
    font-size: 32px;
    line-height: 40px;
    color: var(--secondary-color);
  }
  .total {
    color: var(--primary-color);

    font-size: 32px;
    line-height: 40px;
  }

  .single-price-text {
    font-size: 20px;
    color: var(--tertiary-color);
  }

  .ant-btn {
    font-size: 30px;
  }
}
