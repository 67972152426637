.baggage-management-modal {
  .ant-modal-content {
    width: 700px !important;

    @media screen and  (max-width:750px) {
      width: auto !important;

      .ant-table-cell{
        font-size: 12px;
      }
    }
  }

  #baggage-management {
    .ant-table-cell {
      text-align: center;
    }
    .guest-type {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .modal-title {
      text-align: center;
      color: var(--primary-color);
    }

    .save-btn {
      margin-top: 16px;
    }

    .sale-status {
      text-decoration: underline;
    }

    .flight-direction {
      gap: 8px;
      display: flex;
      align-items: center;
    }

    .ant-table-cell {

    }

    .info-text {
      color: #858585;
    }
  }
}
