.shopping_cart {
  .product-preview-wrapper {
      display: flex;
      column-gap: 20px;

      &.isColumn {
        flex-direction: column-reverse;
      }
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      .cart-title {
        font-weight: bolder;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Limit to 2 lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
      }
      .view-product-label-wrapper {
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
  }

  .attraction-product .product-preview-wrapper .view-product-label-wrapper {
    font-size: 14px;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .product-preview-wrapper.without-cursor-pointer {
    &:hover {
      cursor: unset;
      text-decoration: unset;
    }
  }
}
  
.product-preview-modal {
  &.ant-modal {
      height: 90vh;
      width: 90vw !important;
      margin: auto;
      top: 5vh;
  }
  .ant-modal-content{
      height: 90vh;
      width: 90vw;
      margin: 0;
      top: 0;

      iframe {
          width: 100%;
          height: 100%;
          border: none;
      }
  }
  .ant-modal-header {
      display: none;
  }
  .ant-modal-body {
      padding: 0;
      height: 90vh;
  }
}