.baggage {
  display: flex;
  gap: 10px;

  svg {
    color: var(--primary-color);
  }
  > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    overflow-wrap: anywhere;
    text-align: center;
    font-size: 10px;

    .ant-badge-dot {
      transform: none !important;
      left: 50% !important;
      top: -11px !important;
      transform: translateX(-50%) !important;
      box-shadow: none;
    }
  }
}
