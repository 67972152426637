.shopping-cart-additional-items {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  font-weight: bold;

  .additional-item-amount {
    font-weight: normal;
    color: var(--secondary-color);
  }

  .additional-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13x;
  }
}
